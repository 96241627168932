.customer-review-container{
    background-image: url("../../background-images/customer-reviews-bg.jpg");
    object-fit: contain;

}

.feedback-section-heading{
    padding-top: 5rem;
}
.customer-name{
    padding-bottom: 5rem;
    color: white;
}

.customer-photo{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: contain;
    margin: auto;
}
.customer-words{
    color: rgb(86, 92, 97);
}