@import url('http://fonts.cdnfonts.com/css/times-new-roman');

.tems-container h2{
    font-family: 'Times New Roman', sans-serif;
}

.top-container {
    background-color: black;
    height: 50px;
    /* background-image: linear-gradient(to right, #E8BC45, #E53D74, #4FACD8); */
}

.items-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* MEDIA QUERY */
/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
    .top-container {
        display: none;
    }
}