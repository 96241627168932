.home-services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}




/* MEDIA QUERY */
/* medium devices (768px and bellow) */
@media only screen and (max-width: 768px) {
    .card {
        width: 100% !important;
    }

}