body {
  margin: 0rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* CUSTOM UTILITY CSS */
.redirect-link {
  text-decoration: none;
  font-weight: bold;
}

.text-black {
  color: black;
}


/* UTILITY FOR BUTTON */
.outer-red {
  background-color: transparent;
  border-color: red;
  border: 1px 1px 1 px 1px;
  padding: 0.4rem 1rem;

}

.hover-red:hover {
  background-color: red;
}

.curve-border {
  border-radius: 0.2rem;
}