.section-01, .section-02 {
    display: flex;
    justify-content: center;
    gap: 10px;
}




/* Large devices ( 992px and bellow) */
@media only screen and (max-width: 992px) {
    .section-01 {
        flex-direction: column-reverse;
    }

    .section-02 {
        flex-direction: column;
    }

    .home-about-us-container {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
    }

    .right-side-container {
        width: 100%;
    }
}