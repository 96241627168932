.home-about-us-container {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
}

.left-side-container {
    padding: 0.5rem;
}

.frame {
    border-radius: 1rem;
    width: 100%;
}

.right-side-container {
    padding: 0.5rem;
    width: 40%;
}

.about-home-sub-heading {
    margin-top: 2rem;
}

.home-about-button {
    margin-top: 1rem;
}

/* Large devices ( 992px and bellow) */
@media only screen and (max-width: 992px) {
    .home-about-us-container {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
    }
    .right-side-container {
        width: 100%;
    }
}