/* image size CSS */
.home-sliders-image {
  width: auto;
}

.carousel-item {
  border: 1px solid !important;
}

.carousel-caption {
  max-width: 400px;
  position: absolute;
  top: 100px;
  transform: translate(-50%);
  left: 25%;
}

/* .carousel-caption>h2 {
    font-family: droid-serif;
    font-size: 50px;
    font-weight: 700;
  }
  
  .carousel-inner .carousel-item>img {
    -webkit-animation: zoomin 20s;
    -o-animation: zoomin 20s;
    animation: zoomin 20s;
  }
  
  @keyframes zoomin {
    from {
      transform: scale(1, 1);
    }
    to {
      transform: scale(1.2, 1.2);
    }
  } */


/* Large devices (desktops, 992px and bellow) */
@media only screen and (max-width: 1250px) {
  .carousel-caption {
    /* top: 50px; */
  }
}

@media only screen and (max-width: 992px) {
  
  .carousel-caption {
    top: 25%;
    max-width: 300px;
  }

  .home-slider-description {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .carousel-caption {
    top: 15%;
    max-width: 250px;
  }
}

@media only screen and (max-width:400px) {
  .carousel-caption {
    top: 5%;
    max-width: 200px;
  }
}